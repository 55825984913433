import React, { useState, useCallback, useEffect } from "react"
import { Link } from "gatsby"

import "./hamburger-menu.css"

import useNav from "../../../hooks/useNav"
import useTimeout from "../../../hooks/useTimeout"
import { navLinks } from "../setup"

const NAV_DELAY = 300

export default function HamburgerMenu({ location }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { navTo } = useNav()
  const toggleMenu = useCallback(
    event => {
      event.preventDefault()
      setMenuOpen(open => !open)
    },
    [setMenuOpen]
  )

  const navToOnDelay = useCallback(
    ({ pathname }) => {
      navTo({ pathname })
    },
    [navTo]
  )

  const { runTimeout } = useTimeout(navToOnDelay, NAV_DELAY)

  useEffect(() => {
    if (document && document.body && document.body.classList) {
      if (menuOpen) {
        document.body.classList.add("overflow-hidden")
        document.body.classList.add("md:overflow-auto")
      } else {
        document.body.classList.remove("overflow-hidden")
      }
    }
  }, [menuOpen])

  const burgerMenuOpen = menuOpen ? "burger-menu-open" : ""
  return (
    <nav role="navigation" className={`BurgerMenu ${burgerMenuOpen}`}>
      <ul className="burger-menu-options">
        {navLinks.map(({ pathname, label }) => {
          const isActivePath = pathname === location.pathname
          const dynamicClassName = isActivePath
            ? "text-primary-dark1 transition-all duration-300"
            : "hover:text-tertiary transition-all duration-300"
          return (
            <a
              key={pathname}
              href={pathname}
              className={dynamicClassName}
              onClick={event => {
                event.preventDefault()
                setMenuOpen(false)
                runTimeout({ pathname })
              }}
            >
              <li>{label}</li>
            </a>
          )
        })}
      </ul>
      <a href="#" className="burger-toggle" onClick={toggleMenu}>
        <div className="burger-container">
          <span className="burger-slice slice1"></span>
          <span className="burger-slice slice2"></span>
          <span className="burger-slice slice3"></span>
        </div>
      </a>
    </nav>
  )
}
