import React from "react"

export function Button({
  onClick,
  inverted = false,
  children,
  className = "",
  style = {},
}) {
  let baseButtonClassNames =
    " text-white bg-primary hover:bg-primary-dark1 border-solid border border-primary"
  if (inverted) {
    baseButtonClassNames = "text-white border-solid border border-white"
  }
  return (
    <button
      className={`${baseButtonClassNames} font-bold py-2 px-4 rounded ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  )
}
