import { useCallback } from "react"
import { navigate } from "gatsby"

export default function useNav() {
  const navTo = useCallback(({ pathname }) => {
    navigate(pathname)
  }, [])
  const navToHome = useCallback(() => {
    navigate("/")
  }, [])
  const navToFeatures = useCallback(() => {
    navigate("/features")
  }, [])
  return {
    navTo,
    navToHome,
    navToFeatures,
  }
}
