import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 source image dimensions
 width: 1880
 height: 470
 width to height ratio: 4 (width/height)
*/

/*

text height to logo height ratio:
logo height: 820
text height: 470
ration: 0.573 (text is 0.573 of image height)

// only use this for the adjacent text to match the normal logo
image height: 40 -> text height: 23

*/

const TextImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "alvyApp-text.png" }) {
        childImageSharp {
          fixed(width: 80, height: 20) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageLarge: file(relativePath: { eq: "alvyApp-text.png" }) {
        childImageSharp {
          fixed(width: 92, height: 23) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="HeaderImageText">
      <div
        className="md:hidden flex items-center justify-center"
        style={{
          marginTop: 2,
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.image.childImageSharp.fixed}
          loading="eager"
        />
      </div>
      <div
        className="hidden md:inline flex items-center justify-center"
        style={{ marginLeft: 12, ...style }}
      >
        <Img
          style={{ marginTop: 12 }}
          fadeIn={false}
          fixed={data.imageLarge.childImageSharp.fixed}
          loading="eager"
        />
      </div>
    </div>
  )
}

export default TextImage
