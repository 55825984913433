import React from "react"

import { Heading1, Heading2, Heading3, Text, SmallText } from "./index"

const renderContentItem = ({ contentItem }) => {
  const [itemType, content] = Object.entries(contentItem)[0]
  if (itemType === "header1") {
    return <Heading1>{content}</Heading1>
  }
  if (itemType === "header2") {
    return <Heading2>{content}</Heading2>
  }
  if (itemType === "header3") {
    return <Heading3>{content}</Heading3>
  }
  if (itemType === "paragraph") {
    return (
      <p className="py-3">
        <Text>{content}</Text>
      </p>
    )
  }
  if (itemType === "list") {
    return (
      <ul className="pb-2">
        {content.map((listItem, index) => (
          <li key={`listItem-${index}`} className="py-1 px-3">
            <SmallText>{listItem}</SmallText>
          </li>
        ))}
      </ul>
    )
  }
  if (itemType === "definitions") {
    return (
      <ul className="pb-2">
        {content.map(({ term, meaning }, index) => (
          <li key={`definition-${index}`} className="py-1">
            "<Text bold>{term}</Text>" <Text>{meaning}</Text>
          </li>
        ))}
      </ul>
    )
  }
  if (itemType === "table") {
    return (
      <div className="overflow-x-auto">
        <table className="my-3 border-collapse border-1 border-gray-500 table-auto">
          <tbody>
            {!!content.headerRow1 && (
              <tr>
                {content.headerRow1.map((header, index) => {
                  return (
                    <th
                      key={index}
                      className="border border-gray-400 px-4 py-2 text-gray-800 align-top text-left"
                    >
                      <SmallText>{header}</SmallText>
                    </th>
                  )
                })}
              </tr>
            )}
            {Object.entries(content)
              .filter(([key]) => key.indexOf("header") === -1)
              .map(([key, row], index) => {
                return (
                  <tr key={`row-${index}`}>
                    {row.map((content, index) => {
                      return (
                        <td
                          key={`row-col-${index}`}
                          className="border border-gray-400 px-4 py-2 text-gray-800 align-top text-left"
                        >
                          <SmallText>{content}</SmallText>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div>
      <Text>*** TODO: **** itemType: {itemType}</Text>
    </div>
  )
}

export const DocumentDisplay = ({ doc = [] } = {}) => {
  return doc.map((item, index) => {
    if (item.type === "section") {
      return item.content.map((contentItem, contentItemIndex) => {
        return (
          <div key={index + "__" + contentItemIndex}>
            {renderContentItem({ contentItem })}
          </div>
        )
      })
    }
    return (
      <div key={index}>
        <Text>TO DO: this item type: {item.type}</Text>
      </div>
    )
  })
}
