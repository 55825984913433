import { useEffect, useCallback, useRef } from "react"

const useTimeout = (callback = () => {}, timeout = 0) => {
  const timeoutRef = useRef
  const cancelTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [timeoutRef])
  const runTimeout = useCallback(
    props => {
      timeoutRef.current = setTimeout(() => callback(props), timeout)
    },
    [callback, timeout, timeoutRef]
  )
  useEffect(() => {
    return cancelTimeout
  }, [cancelTimeout])
  return {
    runTimeout,
    cancelTimeout,
  }
}

export default useTimeout
