import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/* 
 source image dimensions
 width: 1230
 height: 820
 width to height ratio: 1.5 (width/height)
*/

export const LogoImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "alvyApp-icon.png" }) {
        childImageSharp {
          fixed(width: 45, height: 30) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageLarge: file(relativePath: { eq: "alvyApp-icon.png" }) {
        childImageSharp {
          fixed(width: 66, height: 44) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <div
        className="md:hidden flex items-center justify-center"
        style={{
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.image.childImageSharp.fixed}
          loading="eager"
        />
      </div>
      <div
        className="hidden md:inline flex items-center justify-center"
        style={{
          ...style,
        }}
      >
        <Img
          fadeIn={false}
          fixed={data.imageLarge.childImageSharp.fixed}
          loading="eager"
        />
      </div>
    </>
  )
}

export default LogoImage
