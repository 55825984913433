import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer className="flex flex-col">
    <div className="flex justify-center py-8 bg-primary text-white">
      <div className="flex">
        <Link to="/legal-stuff/privacy-policy" className="text-center px-4 ">
          privacy policy
        </Link>
        <div className="flex items-center">|</div>
        <Link
          to="/legal-stuff/terms-and-conditions"
          className="text-center px-4 "
        >
          terms and conditions
        </Link>
        <div className="flex items-center">|</div>
        <Link to="/legal-stuff/cookies-policy" className="text-center px-4 ">
          cookies policy
        </Link>
      </div>
    </div>
    <div className="flex justify-center py-6 bg-gray-600 text-white">
      © {new Date().getFullYear()} BIT MORE MEDIA LIMITED
    </div>
  </footer>
)

export default Footer
