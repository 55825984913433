import React from "react"

function propBasedStyles({ props }) {
  let probBasedStyleClass = ""
  if (props.bold) {
    probBasedStyleClass += " font-bold"
  }
  if (props.className) {
    probBasedStyleClass += ` ${props.className}`
  }
  return probBasedStyleClass
}

export const Heading1 = ({
  children,
  style = {},
  colorClass = "text-gray-800",
  ...props
}) => (
  <h1
    className={`text-2xl py-2 ${colorClass} font-play ${propBasedStyles({
      props,
    })}`}
    style={style}
  >
    {children}
  </h1>
)
export const Heading2 = ({
  children,
  style = {},
  colorClass = "text-gray-800",
  ...props
}) => (
  <h2
    className={`text-xl ${colorClass} py-2 font-play ${propBasedStyles({
      props,
    })}`}
    style={style}
  >
    {children}
  </h2>
)
export const Heading3 = ({
  children,
  style = {},
  colorClass = "text-gray-800",
  ...props
}) => (
  <h3
    className={`text-lg py-2 font-mono ${colorClass} ${propBasedStyles({
      props,
    })}`}
    style={style}
  >
    {children}
  </h3>
)
export const Text = ({
  children,
  style = {},
  colorClass = "text-gray-700",
  ...props
}) => (
  <span
    className={`text-base ${colorClass} ${propBasedStyles({
      props,
    })}`}
    style={style}
  >
    {children}
  </span>
)
export const SmallText = ({
  children,
  style = {},
  colorClass = "text-gray-800",
  ...props
}) => (
  <span
    className={`text-sm ${colorClass} ${propBasedStyles({ props })}`}
    style={style}
  >
    {children}
  </span>
)
