import React from "react"
import { Link } from "gatsby"

import { navLinks } from "./setup"

export default function NavLinks({ location }) {
  return (
    <div>
      {navLinks
        .filter(
          ({ pathname }) =>
            pathname !== "/" ||
            (pathname === "/" && pathname !== location.pathname)
        )
        .map(({ pathname, label }) => {
          const isActivePath = pathname === location.pathname
          const dynamicClassName = isActivePath
            ? "text-primary-dark1 transition-all duration-300"
            : "hover:text-tertiary transition-all duration-300"
          return (
            <Link
              key={pathname}
              to={pathname}
              className={`ml-8 ${dynamicClassName}`}
            >
              {label}
            </Link>
          )
        })}
    </div>
  )
}
