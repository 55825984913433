import React from "react"
import { Link } from "gatsby"

import HeaderImageLogo from "./HeaderImageLogo"
import HeaderImageText from "./HeaderImageText"
import HamburgerMenu from "./HamburgerMenu"
import NavLinks from "./NavLinks"

const Header = ({ location }) => (
  <header className="Header flex items-center fixed z-30 w-full border-b border-primary-light1">
    <nav className="flex w-full">
      <div className="flex items-center justify-around container mx-auto px-4">
        <Link to="/" className="flex items-center">
          <HeaderImageLogo style={{ alignSelf: "flex-start" }} />
        </Link>
        <div
          className="flex items-center justify-center md:justify-start"
          style={{ flex: 1 }}
        >
          <Link to="/">
            <HeaderImageText />
          </Link>
        </div>
        <div className="md:hidden relative">
          <HeaderImageLogo style={{ opacity: 0 }} />
          <div
            className="absolute right-0 bottom-0 left-0 flex items-center justify-center"
            style={{ top: 2 }}
          >
            <HamburgerMenu location={location} />
          </div>
        </div>
        <div className="hidden md:inline">
          <NavLinks location={location} />
        </div>
      </div>
    </nav>
  </header>
)

export default Header
